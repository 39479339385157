<template>
    <div id="app">
        <transition
            name="fade"
            mode="out-in"
        >
            <router-view></router-view>
        </transition>
    </div>
</template>

<script>
import loginMixin from './mixins/login'

export default {
    name: 'app',

    mixins: [loginMixin],

    created () {
        setInterval(this.checkIfAccessTokenExpires, 5000)
    },

    methods: {
        checkIfAccessTokenExpires () {
            if (this.accessTokenExpiresIn != null) {
                let diff = this.accessTokenExpiresIn - this.getTime()
                if (diff < 5*60) {
                    this.renewToken(this.rtVoucher)
                }
            }
        }
    }
}
</script>
