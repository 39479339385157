import Vue from 'vue'
import VueI18n from 'vue-i18n'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import messages from './messages'

Vue.use(VueI18n)

let lang = sessionStorage.getItem('lang')
const i18n = new VueI18n({
    locale: lang != null ? lang : 'en',
    messages
})

axios.defaults.headers.common['gs-api-authorization'] = window.GABI_CONFIG.API_KEY

Vue.config.productionTip = false

Vue.mixin({
    data () {
        return {
            get API_URL () {
                return window.GABI_CONFIG.API_URL
            }
        }
    },

    computed: {
        accessToken () {
            return this.$store.state.accessToken
        },

        accessTokenExpiresIn () {
            return this.$store.state.accessTokenExpiresIn
        },

        tokenType () {
            return this.$store.state.tokenType
        },

        rtVoucher () {
            return this.$store.state.rtVoucher
        }
    },

    mounted () {
        if (this.$route.path != '/' && this.accessToken == null) {
            this.$router.push('/')
        }
    }
})

new Vue({
    router,
    store,
    i18n,
    render: h => h(App),
}).$mount('#app')
