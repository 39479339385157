export default {
    en: {
        ui: {
            select_department_or_person: 'Select reception or specific person.',
            name: 'Name',
            surname: 'Surname',
            phone: 'Phone',
            back: 'Back',
            close: 'Close',
            login: 'Login',
            enter_surname: 'Enter the person\'s surname.',
            enter_phone: 'Enter the person\'s phone number.',
        },
        notification: {
            trying_call: 'I\'m trying call: <strong>{employee}</strong>',
            searching: 'Searching&hellip;',
            ringing: 'Ringing&hellip;',
            call_answered: 'Call answered',
            employee_busy: '<strong>{employee}</strong> is busy.',
            employee_did_not_answer: '<strong>{employee}</strong> didn\'t answer.',
            employee_comming: '<strong>{employee}</strong> is comming.',
            no_available_employee_found: 'No available employee found.',
            closed_connection_with_server: 'The connection to the server was closed before the request could be processed properly.',
            communication_error: 'A communication error has occurred',
            loading_departments: 'Loading departments&hellip;',
        }
    },

    cs: {
        ui: {
            select_department_or_person: 'Vyberte recepci nebo specifickou osobu.',
            name: 'Jméno',
            surname: 'Příjmení',
            phone: 'Telefon',
            back: 'Zpět',
            close: 'Zavřít',
            login: 'Login',
            enter_surname: 'Zadejte příjmení hledané osoby.',
            enter_phone: 'Zadejte telefon hledané osoby.',
        },
        notification: {
            trying_call: 'Zkouším vytáčet: <strong>{employee}</strong>',
            searching: 'Hledám&hellip;',
            ringing: 'Vyzvání&hellip;',
            call_answered: 'Hovor přijat',
            employee_busy: '<strong>{employee}</strong> je zaneprázdněn.',
            employee_did_not_answer: '<strong>{employee}</strong> neodpovídá.',
            employee_comming: '<strong>{employee}</strong> přichází.',
            no_available_employee_found: 'Nebyl nalezen žádný dostupný zaměstnanec.',
            closed_connection_with_server: 'Spojení se serverem bylo ukončeno dříve, než mohl být požadavek v pořádku zpracován.',
            communication_error: 'Nastala chyba komunikace',
            loading_departments: 'Načítám oddělení&hellip;',
        }
    }
}