import axios from 'axios'
import { mapMutations } from 'vuex'

export default {
    methods: {
        ...mapMutations([
            'setAccessToken',
            'setAccessTokenExpiresIn',
            'setTokenType',
            'setRTVoucher'
        ]),

        getToken (at_voucher) {
            return new Promise((resolve, reject) => {
                this.callAPIForToken({ at_voucher }, resolve, reject)
            })
        },

        renewToken (rt_voucher) {
            return new Promise((resolve, reject) => {
                this.callAPIForToken({ rt_voucher }, resolve, reject)
            })
        },

        callAPIForToken (params, resolve, reject) {
            axios.post(this.API_URL + 'auth/token', params)
                .then(response => {
                    let data = response.data
                    let access_token = data.access_token
                    let token_type = data.token_type
                    let expires_in = this.getTime() + data.expires_in
                    let rt_voucher = data.rt_voucher

                    sessionStorage.setItem('access_token', access_token)
                    sessionStorage.setItem('token_type', token_type)
                    sessionStorage.setItem('token_expires_in', expires_in)
                    sessionStorage.setItem('rt_voucher', rt_voucher)

                    this.setAccessToken(access_token)
                    this.setAccessTokenExpiresIn(expires_in)
                    this.setTokenType(token_type)
                    this.setRTVoucher(rt_voucher)

                    resolve()
                })
                .catch(error => {
                    reject(error)
                })
        },

        getTime () {
            return Math.round(new Date().getTime() / 1000)
        }
    }
}