<template>
    <div class="login">
        <button @click="login">{{ $t('ui.login') }}</button>
    </div>
</template>

<script>
import axios from 'axios'
import loginMixin from '../mixins/login'

export default {
    name: 'Login',

    mixins: [loginMixin],

    created () {
        let urlParams = new URLSearchParams(window.location.search)
        if (urlParams.has('at_voucher')) {
            let at_voucher = urlParams.get('at_voucher')
            this.getToken(at_voucher)
                .then(() => {
                    this.$router.push('welcome')
                })
                .catch((error) => {
                    alert('Can\'t get access token. More info in console.')
                    console.error(error)
                })
        } else {
            this.login()
        }
    },

    methods: {
        login () {
            let params = {
                redirect_url: window.location.protocol + '//' + window.location.host + '/'
            }

            axios.post(this.API_URL + 'auth/login', params)
                .then(response => {
                    location.href = response.data.auth_url
                })
        }
    }
}
</script>

<style lang="scss">
.login {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
