import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        accessToken: sessionStorage.getItem('access_token'),
        accessTokenExpiresIn: sessionStorage.getItem('token_expires_in'),
        tokenType: sessionStorage.getItem('token_type'),
        rtVoucher: sessionStorage.getItem('rt_voucher')
    },
    mutations: {
        setAccessToken (state, token) {
            state.accessToken = token
        },
        setAccessTokenExpiresIn (state, exporesIn) {
            state.accessTokenExpiresIn = exporesIn
        },
        setTokenType (state, type) {
            state.tokenType = type
        },
        setRTVoucher (state, voucher) {
            state.rtVoucher = voucher
        }
    },
    actions: {

    }
})
