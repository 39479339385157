import Vue from 'vue'
import Router from 'vue-router'
import Login from './views/Login.vue'

Vue.use(Router)

export default new Router({
    mode: 'history',
    routes: [
    {
        path: '/',
        name: 'login',
        component: Login
    },
    {
        path: '/welcome',
        name: 'welcome',
        // route level code-splitting
        // this generates a separate chunk (directory.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "directory" */ './views/Welcome.vue')
    },
    {
        path: '/directory',
        name: 'directory',
        component: () => import('./views/Directory.vue')
    },
    {
        path: '/call/:type/:value',
        name: 'call',
        props: true,
        component: () => import('./views/Call.vue')
    },
    {
        path: '/dial/:type',
        name: 'dial',
        props: true,
        component: () => import('./views/Dial.vue')
    }
  ]
})
